import React from 'react';
import { formatDollarAmount } from '../utils/Formatters';
import {useHistory} from "react-router-dom";

const InventoryCard = ({id, item}) => {
    const history = useHistory();

    const imageUrl = `https://photos.reinventauto.com/cars/${item.photo_name || 'missing.jpg'}`
    return (
        <div className="bg-white rounded-lg shadow-lg" onClick={() => history.push(`/vehicle/unit/${id}`)}>
            <div
                style={{backgroundImage: `url(${imageUrl})`}}
                className="inventory-wrap"
            >
                <div className="static pt-16 pl-32">
                    <button
                      className="absolute p-1 bg-green-200 rounded-lg"
                      onClick={(e) => {
                        e.stopPropagation();
                        alert('Place on Auction')
                      }}
                    >
                      Place On Auction
                    </button>
                </div>
            </div>


            <div className="">
                <div className="flex inline-flex m-2">
                    <span className="text-sm">{item && item.title}</span>
                </div>

                <div className="flex inline-flex m-2 text-sm">
                    <span className="orange">{item && formatDollarAmount(item.price)}</span>
                    <span className="mx-2">|</span>
                    <span>{item && item.distance || 0} miles</span>
                </div>
            </div>
        </div>
    )
}

export default InventoryCard;