import FormControl from "./widgets/FormControl";
import classNames from "classnames";


export default function AddressView ({handleChange, values, errors, touched}) {

  return (
    <div className='col-span-12 mt-8'>
      <FormControl
        label='Corporate Address'
        type='text'
        name='dealer_address'
        handleChange={handleChange}
        errors={errors}
        values={values}
        touched={touched}
      />

      <FormControl
        label='Address 2'
        type='text'
        name='dealer_address2'
        handleChange={handleChange}
        errors={errors}
        values={values}
        touched={touched}
      />

      <div className='grid grid-cols-12'>
        <div className='col-span-5'>
          <FormControl
            label='City'
            type='text'
            name='dealer_city'
            handleChange={handleChange}
            errors={errors}
            values={values}
            touched={touched}
          />
        </div>

        <div className='col-span-3'>
          <FormControl
            label='State'
            type='text'
            name='dealer_state'
            handleChange={handleChange}
            errors={errors}
            values={values}
            touched={touched}
          />
        </div>

        <div className='col-span-4'>
          <FormControl
            label='Zip Code'
            type='text'
            name='dealer_zip'
            handleChange={handleChange}
            errors={errors}
            values={values}
            touched={touched}
          />
        </div>
      </div>
    </div>
  )
}