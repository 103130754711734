import React from "react";
import {useAuth, useProvideAuth, authContext} from "./utils/UserContext";
import MainLayout from "./layouts/MainLayout";
import LoginView from './views/LoginView';
import AppView from './views/App';
import DealerView from './views/DealerView/';
import InventoryView from './views/InventoryView/';
import MessagesView from './views/MessagesView/';
import VehicleView from './views/VehicleView/';

import {
    HashRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import SignupView from "./views/SignupView";

const AppRouter = () => {
    const auth = useProvideAuth();
    const ProvideAuth = ({ children }) => {

        return (
          <authContext.Provider value={auth}>
              {children}
          </authContext.Provider>
        );
    }

    const PrivateRoute = ({ children, ...rest }) => {
        const auth = useAuth();
        return (
          <Route
            {...rest}
            render={({ location }) =>
              auth.token ? (
                children
              ) : (
                <Redirect
                  to={{
                      pathname: "/login",
                      state: { from: location }
                  }}
                />
              )
            }
          />
        );
    }

    return (
      <ProvideAuth>
          <Router>
              <Switch>
                  <Route exact path="/">
                      {/*<MainLayout>*/}
                      {/*    {auth.token ? (<DealerView/>) : (<AppView/>)}*/}
                      {/*</MainLayout>*/}
                      <MainLayout>
                          <SignupView/>
                      </MainLayout>
                  </Route>

                  <Route path="/login">
                      <MainLayout>
                          <LoginView/>
                      </MainLayout>
                  </Route>

                  <Route path="/signup">
                      <MainLayout>
                          <SignupView/>
                      </MainLayout>
                  </Route>

                  <PrivateRoute path="/inventory">
                      <MainLayout>
                          <InventoryView/>
                      </MainLayout>
                  </PrivateRoute>

                  <PrivateRoute path="/messages">
                      <MainLayout>
                          <MessagesView/>
                      </MainLayout>
                  </PrivateRoute>

                  <PrivateRoute path="/vehicle/unit/:id">
                      <MainLayout>
                          <VehicleView />
                      </MainLayout>
                  </PrivateRoute>
              </Switch>
          </Router>
      </ProvideAuth>
    )
}

export default AppRouter;