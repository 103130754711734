import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import InputBox from "../components/widgets/InputBox";
import Button from "../components/widgets/Button";
import API from '../utils/API';
import {useAuth} from "../utils/UserContext";
import spinnerImage from "../icons/spinner.svg";

const LoginView = () => {
    const auth = useAuth();
    const history = useHistory();

    const [error, setError] = useState(false);

    const handleSubmit = async (values, {setSubmitting}) => {
        console.log(values);
        const data = await API.post('/user/login', values);
        console.log(data);
        setSubmitting(false);

        if (data.redirect) {
            const respData = data.redirect[1];
            console.log(respData.error_msg);
            setError(respData.error_msg);
        }
        else {
            if (data.cookieToSet) {
                data.cookieToSet.map(cookie => document.cookie = cookie.replace('HttpOnly', ''));
            }

            const token = data.token[0] || data.cookieToSet.token;
            auth.loggedIn(data.user, token);
            history.push('/' );
        }
    }

    return (
        <div className="container mx-auto px-4checkerboard">
            <div className="rounded-sm bg-white shadow-md ml-80 mr-80 mt-40">
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={ handleSubmit }
                >
                    {({ isSubmitting, values, handleBlur, handleChange, errors}) => (
                    <Form>
                        <div className="mx-auto container flex flex-col rounded shadow ">
                            <div className="mt-5 flex justify-center">
                                <div className="text-3xl">Login</div>
                            </div>

                            <div className="flex flex-col">
                                <Field
                                    component={InputBox}
                                    fieldName="email"
                                    values={values}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>

                            <div className="flex flex-col">
                            <Field
                                component={InputBox}
                                type="password"
                                fieldName="password"
                                values={values}
                                errors={errors}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                            />
                            </div>

                            <div className="flex flex-col">
                                {error &&
                                    <p>{error}</p>
                                }
                            </div>

                            <div className="flex flex-col mt-5 ml-5 mb-10 justify-end">
                                <Button className="w-40" type="submit" disabled={isSubmitting}>
                                    <div className={isSubmitting ? "grid grid-cols-2" : ""}>
                                        <div hidden={!isSubmitting}>
                                            <spinnerImage />
                                        </div>
                                        <div>
                                            Submit
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default LoginView;