import React from "react";

const Button = ({type, onClick, children, className, disabled}) => {
    return (
      <button
        className={`${className === undefined ? '' : className} p-3 rounded-lg pl-5 pr-5`}
        type={type}
        disabled={disabled}
        onClick={onClick}>
          {children}
      </button>
    )
}

export default Button;