import React from "react";

const Select = ({items, values, errors, touched, fieldName, handleChange, handleBlur}) => {
  const feedback = touched && touched[fieldName] && errors && errors[fieldName];
  const value = values && values[fieldName];

  return (
    <div className="flex flex-col mt-5 mr-5">
      <select
        className="rounded-lg border-black border p-3"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        name={fieldName}
        error={feedback !== undefined}
      >
        <option value="">--- Select One ---</option>
        {items && items.map(item => <option key={`${item.value}-option`} value={item.value}>{item.key}</option>)}
      </select>
      { feedback &&
        <div className='text-danger' id={`${fieldName}-feedback`}>{feedback}</div>
      }
    </div>
  )
}

export default Select;