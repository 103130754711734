import React from "react";

const VehicleInformationPanel = ({vehicle}) => {

  return (
    <div>
      <div className="mb-5 text-3xl">Vehicle Details</div>

      <div className="overflow-hidden p-5 rounded-lg shadow-lg bg-white">
      </div>
    </div>
  );
}

export default VehicleInformationPanel