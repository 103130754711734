import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PhotoViewer = ({photos}) => {

  return (
    <div className="overflow-hidden rounded-lg shadow-lg bg-white">
      <Carousel showArrows={true}>
        {photos && photos.map(photo => {
          const imageUrl = `https://photos.reinventauto.com/cars/${photo.replace(/"|'/g, '') || 'missing.png'}`;

          return (
            <div style={{maxHeight: 600}} key={photo}>
              <img  style={{maxHeight: 600}}  src={imageUrl}/>
            </div>
          );
        })}
      </Carousel>
    </div>
  )
}

export default PhotoViewer;