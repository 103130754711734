import InputBox from "./InputBox";
import Select from "./Select";

export default function({name, label, className, type, items, handleChange, errors, touched, values}) {
  return (
    <div className={`${className} col-span-12 mt-8`}>
      <div className='col-span-12'>
        <label>{label}</label>
      </div>

      <div className='col-span-12'>
        {type !== 'select' && (
          <InputBox
            fieldName={name}
            type={type}
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}

        {type === 'select' && (
          <Select
            fieldName={name}
            values={values}
            items={items}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
          />
        )}
      </div>
    </div>
  )
}