import React, {useEffect, useState} from 'react';
import SignupEmailDialog from "../../components/dialogs/SignupEmailDialog";
import SingupForm from "../../components/forms/SignupForm";
import TermsAndConditionsForm from "../../components/forms/TermsAndConditionsForm";
import API from '../../utils/API';
import Spinner from "../../components/Spinner";

const SignupView = () => {

  const [dataProviders, setDataProviders] = useState([])
  const [referralSources, setReferralSources] = useState([])
  const [formInputValues, setFormInputValues] = useState({})
  const [step, setStep] = useState(1)
  const [errorData, setErrorData] = useState()
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    const getData = async () => {
      const newDataProviders = await API.get('/api/dms/siteTypes')
      const newReferralSources = await API.get('/api/referral/sources')

      setDataProviders(newDataProviders.map(provider => {
        return {
          value: provider.value,
          key: provider.text
        }
      }))
      setReferralSources(newReferralSources.map(source => {
        return {
          value: source.value,
          key: source.text
        }
      }))
    }

    getData()
  }, [])

  const handleSubmit = async values => {
    setFormInputValues(values)
    setStep(2)
  }

  const handleFinalize = async values => {
    try {
      setProcessing(true)

      const formData = {
        ...formInputValues,
        is_rv: formInputValues.verticals.includes('is_rv') ? '1' : undefined,
        is_auto: formInputValues.verticals.includes('is_auto') ? '1' : undefined,
        is_powersports: formInputValues.verticals.includes('is_powersports') ? '1' : undefined,
      };

      const response = await API.post('/api/dealer/signup/', formData);

      if (response.error ) {
        if (response.error.type === "email-exists") {
          setErrorData({
            ...formData
          })
        }
      }
      else {
        setFormInputValues({})
        setStep(3)
      }

      setProcessing(false)
    }
    catch(error) {
      console.log({message: 'error in finalize', error: error.message})
      setProcessing(false)
    }
  }

  return (
    <div className="grid grid-cols-12 px-8 pb-3">
      <div className="width-48 ml-8 mt-1 col-span-12 text-3xl" >
          Sign Up for the Reinvent Platform
      </div>

      {/*<div className='col-span-12 ml-8 mt-8'>*/}
      {/*  <div className="grid grid-cols-4  gap-4 m-auto">*/}
      {/*    <div className="border-t-4 border-primary pt-4">*/}
      {/*      <p className="uppercase text-primary font-bold">Step 1</p>*/}

      {/*      <p className="font-semibold">Signup Information</p>*/}
      {/*    </div>*/}
      {/*    <div className={`${step >= 2 ? 'border-primary': ''} border-t-4 grid-cols-4 pt-4`}>*/}
      {/*      <p className='uppercase text-primary font-bold'>Step 2</p>*/}

      {/*      <p className="font-semibold">Terms and Conditions</p>*/}
      {/*    </div>*/}

      {/*    <div className={`${step === 3 ? 'border-primary': ''} border-t-4 grid-cols-4 pt-4`}>*/}
      {/*      <p className='uppercase text-primary font-bold'>Step 3</p>*/}

      {/*      <p className="font-semibold">Complete</p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {step === 1 && (
        <>
          <div className="border-t-4 width-48 pt-5 ml-8 mt-5 col-span-12" >
              Enter your dealership information to begin transacting on the Reinvent Platform
          </div>

          <div className="ml-8 mt-5 col-span-12" >
            <SingupForm
              values={formInputValues}
              referralSources={referralSources}
              dataProviders={dataProviders}
              handleSubmit={handleSubmit}
            />
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="border-t-4 width-48 pt-5 ml-8 mt-5 col-span-12" >
            Read and accept the terms and conditions for the site.
          </div>

          <div className="ml-8 mt-5 col-span-12" >
            <TermsAndConditionsForm
              handleBackClicked={() => setStep(1)}
              handleSubmit={handleFinalize}
            />
          </div>
        </>
      )}

      {step === 3 && (
        <div className="ml-8 mt-5 col-span-12" >
          <b className="text-2xl">Dealer Sign Up for Complete</b>
          <div>
            <p className="mt-3 text-lg">Thank you for registering on the Reinvent Platform</p>
            <p className="mt-3">
            You will receive an email with your dealer username and password. Also, a training representative will contact
            you to walk you through the process of loading your inventory onto the platform as well as how to use the platform
            to sell and locate new inventory.
            </p>
          </div>
        </div>
      )
      }

      {processing &&
        <Spinner/>
      }

      <SignupEmailDialog
        show={errorData !== undefined}
        data={errorData}
        handleCloseClicked={() => setErrorData(undefined)}
      >
      </SignupEmailDialog>
    </div>
  )
}

export default SignupView;