import React, {useEffect, useState} from 'react';
import {useHistory, useParams, Link} from "react-router-dom";
import API from '../../utils/API';
import Spinner from "../../components/Spinner";
import PhotoViewer from "../../components/PhotoViewer";
import Button from "../../components/widgets/Button";
import VehicleDetailsPanel from "./components/VehicleDetailsPanel";
import ConditionReportPanel from "./components/ConditionReportPanel";
import InventoryCard from "../../components/InventoryCard";
import VehicleInformationPanel from "./components/VehicleInformationPanel";
import DealerInformationPanel from "./components/DealerInformationPanel";

const InventoryView = () => {
  const [vehicleData, setVehicleData] = useState({});
  const [showLoading, setShowLoading] = useState(false);

  const { vehicle, inventory, myDealer, photos, user } = vehicleData;

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const getVehicleData = async () => {
      setShowLoading(true);

      const data = await API.get(`/vehicle/unit/title.${id}`);
      setVehicleData(data);

      setShowLoading(false);
    }

    getVehicleData();
  }, [id]);

  return (
    <div className="flex">
      <div className="grid grid-cols-12">
        <div className="mt-2 col-span-12">
          <nav
            className="text-sm text-left bg-gray-500 bg-opacity-10 border border-gray-400 h-12 flex items-center p-4 rounded-md"
            role="alert"
          >
            <ol className="list-reset flex">
              <li><Link className="text-blue-dark" to="/">Dashboard</Link></li>
              <li><span className="mx-2">/</span></li>
              <li><Link to={`/dealer/${vehicle && vehicle.dealer_token}`}>{vehicle && vehicle.dealer_name}</Link></li>
              <li><span className="mx-2">/</span></li>
              <li><span>{vehicle && vehicle.title}</span></li>
            </ol>
          </nav>
        </div>

        <div className="mr-5 photo-panel col-span-9">
          <div className="mb-5 text-4xl">{vehicle && vehicle.title}</div>
          <PhotoViewer photos={photos} />
        </div>

        <div className="details-panel col-span-3">
          <div className="">
            <Button className="auction-start mb-5">Place on Auction</Button>
          </div>

          <div className="mt-5">
            <VehicleDetailsPanel vehicle={vehicle}/>
          </div>
        </div>

        <div className="mt-10 condition-panel col-span-12">
          <ConditionReportPanel vehicle={vehicle} />
        </div>

        <div className="mt-10 condition-panel col-span-12">
          <VehicleInformationPanel vehicle={vehicle} />
        </div>

        <div className="mt-10 condition-panel col-span-12">
          <DealerInformationPanel vehicle={vehicle} />
        </div>

        <div className="mt-10 condition-panel col-span-12">
          <div className="mb-5 text-3xl">More Vehicles from this dealer</div>

          <div className="grid lg:grid-cols-4 xs:grid-cols-2 md:grid-cols-2 gap-4">
            {inventory &&
              inventory.map( item => <InventoryCard id={item.token} item={item} />)
            }
          </div>
        </div>
      </div>

      {showLoading && (
        <Spinner />
      )}
    </div>
  )
}

export default InventoryView;