import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

import infoImage from "../../../icons/info.svg";
import {getShippingTooltip} from "../../../utils/Shipping";

const VehicleDetailsPanel = ({vehicle}) => {

  const getPrice = () => {
    const {price, mine} = vehicle || {};

    if (price > 0) {
      return price;
    }

    if (mine) {
      return 'N/A';
    }

    return 'Contact Dealer';
  }

  return (
    <div className="overflow-hidden p-5 rounded-lg shadow-lg bg-white">
      <div>
        <div>Price</div>
        <div className="text-3xl">{ getPrice() }</div>
      </div>

      <div className="mt-2">
        <div>
          <div className="float-left">Shipping</div>

          <div className="float-left ml-2">
            <Tooltip placement="top" trigger={['hover']} overlay={<span>{getShippingTooltip(vehicle)}</span>}>
              <img src={infoImage} />
            </Tooltip>
          </div>
        </div>

        <div>
          <div className="clear-left text-3xl">{ getPrice() }</div>
        </div>
      </div>

      <div className="mt-2">
        <div>Mileage</div>
        <div className="text-3xl">{ vehicle && vehicle.mileage }</div>
      </div>

      <div className="mt-2">
        <div>Vin</div>
        <div className="text-3xl">{ vehicle && vehicle.vin }</div>
      </div>

      { vehicle && vehicle.category !== 'Travel Trailer' && (
        <div className="mt-2">
          <div>Category</div>
          <div className="text-3xl">{ vehicle && vehicle.category }</div>
        </div>
      )}

      <div className="mt-2">
        <div>Floor Plan</div>
        <div className="text-3xl">{ (vehicle && vehicle.floor_plan) || 'N/A' }</div>
      </div>

      <div className="mt-2">
        <div>Sleeps</div>
        <div className="text-3xl">{ (vehicle && vehicle.sleeping_capacity) || 'N/A' }</div>
      </div>

      <div className="mt-2">
        <div>Location</div>
        <div className="text-3xl">{ vehicle && vehicle.location }</div>
      </div>
    </div>
  );
}

export default VehicleDetailsPanel;