import React from "react";

const InputBox = ({type, values, errors, touched, fieldName, handleChange, handleBlur}) => {
  const feedback = touched && touched[fieldName] && errors && errors[fieldName];
  const value = values && values[fieldName];

  return (
    <div className='flex flex-col width-full mt-3 mr-5'>
      <input
        className='rounded-lg border-black border p-3'
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        name={fieldName}
        error={feedback !== undefined}
      />
      { feedback &&
      <div className='text-danger' id={`${fieldName}-feedback`}>{feedback}</div>
      }
    </div>
  )
}

export default InputBox;