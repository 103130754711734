import { createContext, useContext, useState, useEffect } from "react";
import { useStateWithLocalStorage } from '../utils/useStateWithLocalStorage';
import { getCookie } from './Cookies'
import { useHistory } from 'react-router-dom';

export const authContext = createContext();

export const useAuth = () => {
    return useContext(authContext);
}

export const useProvideAuth = () => {
    const [user, setUser] = useState();
    const [userData, setUserData] = useStateWithLocalStorage('user');
    const [token, setToken] = useStateWithLocalStorage('token');
    const history = useHistory();

    const checkLogin = () => {
        if (!['/login', '/signup'].contains(window.location.pathname) && !getCookie("uid")) {
            history.push('/login#expired');
        }
        else {
            setTimeout(function () {
                checkLogin();
            }, 5000);
        }
    }

    useEffect(() => {
        if( userData ) {
            setUser( JSON.parse(userData) );
        }
    }, [userData]);

    const loggedIn = (user, token) => {
        setUserData(user ? JSON.stringify(user) : user);
        setToken(token);
    };

    const signout = () => {
        setUserData(undefined);
        setToken( undefined);
    };

    return {
        user,
        token,
        loggedIn,
        signout
    };
}