import React from 'react';
import NavBar from "../components/NavBar";

const MainLayout = ({children}) => {

    return (
        <div className="min-h-screen bg-gray-100 min">
            <div className="h-auto bg-white">
                <NavBar />
            </div>

            <div className="container mx-auto px-4 py-8">
                {children}
            </div>

            <div>

            </div>
        </div>

    )
}

export default MainLayout;