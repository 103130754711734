import React from "react";

const Checkbox = ({name, label, errors, checked, fieldName, handleChange, handleBlur}) => {
  const feedback = errors && errors[name];

  return (
    <div className={`float-left mt-3 ml-4`}>
      <input
        className='rounded-lg border-black border p-3'
        type='checkbox'
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        error={feedback !== undefined}
        checked={checked}
      />
      <label className='ml-2'>{label}</label>
      { feedback &&
      <div className='text-danger' id={`${name}-feedback`}>{feedback}</div>
      }
    </div>
  )
}

export default Checkbox;