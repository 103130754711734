import React from "react";
import Dialog from './Dialog'

export default function SignupEmailDialog({show, handleCloseClicked, data}) {
  const getSiteLocation = () => {
    if (data.is_rv) {
      return 'https://dealer.reinventrv.com'
    }
    else if (data.is_powersports) {
      return 'https://dealer.reinventpowersports.com'
    }
  }

  return (
    <Dialog
      show={show}
      handleCloseClicked={handleCloseClicked}
      title='Email already Exists'
    >
      <p>You are already registered using the email address {data && data.contact_email}.</p>
      <a className='text-blue-800' href={data && getSiteLocation()}>Please login or reset your password.</a>
    </Dialog>
  )
}
