import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../utils/UserContext";
import squaresImage from '../icons/squares.svg';
import chatImage from "../icons/messages.svg";
import menuImage from "../icons/menu.svg";
import homeImage from "../icons/home.svg";
import tagImage from "../icons/tag.svg";
import bookmarkImage from "../icons/bookmark.svg";
import searchImage from "../icons/search.svg";
import lockImage from "../icons/lock.svg";
import questionImage from "../icons/question.svg";
import signoutImage from "../icons/sign-out.svg";

const NavBar = () => {
    const auth = useAuth();

    const user = auth && auth.user;

    return (
        <div className="grid grid-cols-12 px-8 pb-3 shadow">
            <div className="width-48 ml-10 mt-1 sm:col-span-3 col-span-12" >
                {/*<Link to="/" id="nav-logo">*/}
                    <img alt="Reinvent RV Logo" src="/static/images/reinvent-auctions.png" />
                {/*</Link>*/}
            </div>

            {/*{!user &&*/}
            {/*<div className="col-span-10 mr-5 flex flex-wrap content-end justify-end">*/}
            {/*    <Link to="/login" id="nav-logo">Login</Link>*/}
            {/*</div>*/}
            {/*}*/}

            {user && (
            <div className="col-span-10 mr-5 flex flex-wrap content-end justify-end">
                <div className="mr-3">
                    {user.first_name} {auth.user.last_name}<br/>
                    {user.dealer_name}
                </div>
                <div className="ml-8 flex justify-center">
                    <Link className="flex justify-center" to="/inventory">
                        <img src={squaresImage} className="" alt="Inventory" />
                    </Link>
                </div>
                <div className="flex justify-center ml-8">
                    <Link className="flex justify-center" to="/messages">
                        <img src={chatImage} className="" alt="Messages" />
                    </Link>
                </div>
                <div className="dropdown inline-block relative mt-3 ml-8 pr-9">
                    <img className="text-black-500" src={menuImage} alt="Menu" />
                    <div className="dropdown-content bg-gray-200 p-3 absolute top-5 right-0 min-w-max z-10 rounded-lg shadow">
                        <Link className="block" to="/">
                            <img alt="home" className="float-left mr-2" src={homeImage} />
                            Home
                        </Link>

                        <div className="divide-solid"/>

                        <Link className="block mt-1" to="/inventory">
                            <img alt="inventory" className="float-left mr-2" src={homeImage} />
                            My Inventory
                        </Link>
                        <Link className="block mt-1" to="/auctions#mine">
                            <img alt="auctions" className="float-left mr-2" src={tagImage} />
                            My Auctions
                        </Link>
                        <Link className="block mt-1" to="/bids">
                            <img alt="bids" className="float-left mr-2" src={tagImage} />
                            My Bids
                        </Link>
                        <Link className="block mt-1" to="/messages">
                            <img alt="messages" className="float-left mr-2" src={chatImage} />
                            My Messages
                        </Link>
                        <Link className="block mt-1" to="/bookmarks">
                            <img alt="bookmarks" className="float-left mr-2" src={bookmarkImage} />
                            My Bookmarks
                        </Link>

                        <hr/>

                        <Link className="block mt-1" to="/search">
                            <img alt="search"  className="float-left mr-2" src={searchImage} />
                            Search Vehicles
                        </Link>
                        <Link className="block mt-1" to="/auctions">
                            <img alt="auctions" className="float-left mr-2" src={tagImage} />
                            All Auctions
                        </Link>

                        <hr/>

                        <Link className="block mt-1" to="/sold">
                            <img alt="sold" className="float-left mr-2" src={tagImage} />
                            Sold Vehicles
                        </Link>
                        <Link className="block mt-1" to="/purchased">
                            <img alt="purchased" className="float-left mr-2" src={tagImage} />
                            Purchased Vehicles
                        </Link>

                        <hr/>

                        <Link className="block mt-1" to="/password-change">
                            <img alt="change password" className="float-left mr-2" src={lockImage} />
                            Change Password
                        </Link>
                        <Link className="block mt-1" to="/help">
                            <img alt="help" className="float-left mr-2" src={questionImage} />
                            Contact Us
                        </Link>
                        <Link className="block mt-1" to="/logout">
                            <img alt="logout" className="float-left mr-2" src={signoutImage} />
                            Logout
                        </Link>
                    </div>
                </div>
            </div>)
            }
        </div>
    )
}

export default NavBar;